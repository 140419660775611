import OrderedBullet from 'components/OrderedBulletList/OrderedBullet'

interface Props {
	content: Array<string | JSX.Element>
}

export default function OrderedBulletList(props: Props) {
	return (
		<div style={{ display: 'flex', flexDirection: 'column', gap: '25px' }}>
			{props.content.map((e: string | JSX.Element, i: number) => {
				return (
					<div key={i} style={{ display: 'flex', gap: '10px' }}>
						<OrderedBullet number={i + 1} />
						{e}
					</div>
				)
			})}
		</div>
	)
}
