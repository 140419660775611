import { ButtonHTMLAttributes } from 'react'

interface Props extends ButtonHTMLAttributes<any> {
	label: string
	megaBlock?: boolean
}

export default function Button(props: Props): JSX.Element {
	return (
		<div className={props.megaBlock ? 'd-grid' : ''}>
			<button style={{ color: 'white' }} type="button" className="btn btn-primary" {...props}>
				{props.label}
			</button>
		</div>
	)
}
