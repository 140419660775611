import Footer from '../components/Footer'
import Navigation from '../components/Navigation'
import NavigationNew from '../components/Navigation2'

import Header from 'pages/Header'
import Calendar from 'pages/Calendar'
import Contact from 'pages/Contact'
import Rate from 'pages/Rate'
import About from 'pages/About'
import Help from 'pages/Help'
import ReasonsForIt from 'pages/ReasonsForIt'
import WhatIsIt from 'pages/WhatIsIt'

export default function Index() {
	return (
		<>
			<NavigationNew />
			<Header />
			<div id="page-what-is-it">
				<WhatIsIt />
			</div>
			<div id="page-reasons-for-it" className="navigation">
				<ReasonsForIt />
			</div>
			<div id="page-help" className="navigation">
				<Help />
			</div>
			<div id="page-about" className="navigation">
				<About />
			</div>
			<div id="page-rate" className="navigation">
				<Rate />
			</div>
			<div id="page-calendar" className="navigation">
				<Calendar />
			</div>
			<div id="page-contact" className="navigation">
				<Contact />
			</div>
			<Footer />
		</>
	)
}
