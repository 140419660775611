interface Props {
	number: number
}

export default function OrderedBullet(props: Props) {
	const size: number = 37

	return (
		<div
			style={{
				backgroundColor: '#ac9957',
				borderRadius: '100%',
				minWidth: `${size}px`,
				height: `${size}px`,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				color: 'white',
				fontSize: '1.5em',
				fontFamily: 'Alumni Sans',
				fontWeight: '500',
			}}
		>
			<div
				style={{
					marginTop: '1px',
				}}
			>
				{props.number}
			</div>
		</div>
	)
}
