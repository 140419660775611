import Button from 'components/Button/Button'

export default function Rate() {
	return (
		<div className="background-color-base">
			<div className="container normal">
				<div className="row">
					<div className="col-sm-8">
						<h1>Tarife</h1>
						<p>
							Der Stundeneinsatz CHF 150.– gilt für Konsultationszeit in der Praxis,
							sowie Fallanalyse (Repertorisation) in Abwesenheit des Patienten.
						</p>

						<p>
							Die erste Anamnese beträgt in der Regel ein bis zwei Stunden. Weitere
							Kontrollen und Folgebehandlungen 30–45 min.
						</p>

						<p>
							Ich bin Mitglied des{' '}
							<span className="bold">Homöopathie Verbandes Schweiz (HVS)</span>,
							verfüge über das <span className="bold">EMR-Qualitätslabel</span> und
							somit sind meine Behandlungen über die Zusatzversicherung von den
							meisten Krankenkassen anerkannt.
						</p>

						<p className="bold">
							Bitte informieren Sie sich, ob Ihre Krankenkasse die Kosten der
							Behandlung übernehmen wird.
						</p>

						<p>
							Vor der ersten Besprechung bringen Sie bitte das ausgefüllt PDFFormular
							«Patienten und Familienanamnese» und nach Möglichkeit ein aktuelles
							Passfoto mit.
						</p>
					</div>
					<div className="col-sm-4" style={{ marginTop: '0px' }}>
						<img src="img/zertifikat.jpg" className="img-fluid" />
						<br />
						<br />
						Patienten und Familienanamnese:
						<a href="download/Anamnesenbogen.pdf" download="Anamnesenbogen.pdf">
							<Button label="Download" megaBlock={true} />
						</a>
					</div>
				</div>
			</div>
		</div>
	)
}
