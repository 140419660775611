type NavigationType = {
	name: string
	href: string
}

export default function Navigation() {
	const navigation: NavigationType[] = [
		{ name: 'Was ist Homöopathie?', href: '#page-what-is-it' },
		{ name: '7 Gründe', href: '#page-reasons-for-it' },
		{ name: 'Wo hilfts?', href: '#page-help' },
		{ name: 'Über mich', href: '#page-about' },
		{ name: 'Tarife', href: '#page-rate' },
		{ name: 'Termine', href: '#page-calendar' },
		{ name: 'Kontakt', href: '#page-contact' },
	]

	function renderNavigation(navigationArray: NavigationType[]): JSX.Element {
		return (
			<div>
				{navigationArray.map((e: NavigationType, i: number) => {
					return (
						<div key={i} className="zoom">
							<a href={`/${e.href}`}>{e.name}</a>
						</div>
					)
				})}
			</div>
		)
	}
	return (
		<nav className="my-navigation container">
			<img src="img/logo.jpg" className="img-fluid" />
			<div>
				{renderNavigation(navigation.slice(0, 3))}
				{renderNavigation(navigation.slice(3, 9))}
			</div>
		</nav>
	)
}
