import NavigationNew from '../components/Navigation2'
import Button from 'components/Button/Button'

export default function NotFound(): JSX.Element {
	function handleButtonClick() {
		window.location.href = '/'
	}
	return (
		<>
			<NavigationNew />

			<div className="background-color-base">
				<div className="container normal">
					<h1>Fehler 404</h1>

					<p>Diese Seite konnte nicht gefunden werden.</p>
					<Button label="Zurück zur Homepage" onClick={handleButtonClick} />
				</div>
			</div>
		</>
	)
}
