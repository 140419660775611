export default function WhatIsIt() {
	return (
		<div className="background-color-base">
			<div className="container normal">
				<h1>Was ist Homöopathie?</h1>

				<p>
					Jeder hat schon einmal von Homöopathie gehört, ohne zu wissen, was sie wirklich
					ist. Einige meinen, es handelte sich um eine Behandlung mit Kräutern
					(Phytotherapie genannt), oder mit Substanzen natürlichen Ursprungs
					(Naturheilkunde), andere meinen, es handelte sich um eine Behandlung mit kleinen
					Dosen von Medikamenten, wieder andere vermuten, es handelte sich um Hexerei.
				</p>

				<p>
					Es ist jedoch ganz einfach: Homöopathie wirkt, weil sie sich, wie jede
					anständige Wissenschaft auf ein in der Natur tatsächlich existierendes Geset
					«Ähnliches wird durch Ähnliches geheilt» stütz und es konsequent umsetzt.
				</p>
				<p>
					Es ist wie in der Mathematik: Wenn alles nach den Regeln gelöst wird, ist es
					kein Wunder, dass die Summe stimmt.
				</p>
				<p style={{ marginBottom: '0px' }}>
					Die Homöopathie lässt sich gut mit der Schulmedizin vereinbaren, eine
					medizinische Behandlung optimieren und, unter Umständen, Verbrauch von starken
					Medikamenten erheblich reduzieren und Lebensqualität des kranken Menschen
					erhöhen.
				</p>
			</div>
		</div>
	)
}
