import OrderedBulletList from 'components/OrderedBulletList/OrderedBulletList'

export default function ReasonsForIt() {
	const content: string[] = [
		'Anstelle der Symptombekämpfung widmen wir uns den Ursachen, die einen Menschen krank machen!',
		'Homöopathische Mittel wirken wie Katalysatoren, die die körpereigenen Abwehrkräfte anregen und so die Heilungskräfte für eine vollständige Genesung mobilisieren. Und das sind vollständig bewiesene Fakten',

		'Die Homöopathie basiert auf dem Heilungsgesetz des Hippokrates – dem Gesetz der Ähnlichkeit «Ähnliches wird durch Ähnliches geheilt». Stoffe, die in normalen Konzentrationen Krankheiten verursachen, behandeln in extrem niedrigen Dosen – die gleichen Symptome bei kranken Menschen. Mit anderen Worten: Die richtige Anwendung der Homöopathie verbessert die Gesundheit des Patienten erheblich! Das Ergebnis einer solchen Behandlung ist eine vollständige Heilung. Die Schulmedizin bedient sich eines anderen, ebenfalls von Hippokrates entdeckten Gesetzes – des Gesetzes der Gegensätze: Die Krankheit wird mit chemischen Präparaten behandelt, die die Symptome unterdrücken und die Krankheit «nach innen» treiben und damit die pathologischen Prozesse vertiefen.',

		'Das Grundprinzip der Homöopathie lautet: «Behandle den Patienten, nicht die Krankheit!» Jeder Mensch ist einzigartig, so wie seine Behandlung. Individualität ist die Grundlage der homöopathischen Behandlung vor 200 Jahren und heute.',

		'Die Behandlung mit homöopathischen Mitteln macht nicht süchtig, hat keine Nebenwirkungen und verursacht keine allergischen Reaktionen',

		'Ein guter Homöopath betrachtet den ganzen Menschen und achtet auf alle seine Beschwerden - körperlich, emotional und psychologisch. Die Behandlung findet auf allen «Ebenen der Pathologie» statt. Mit homöopathischen Mitteln können Kopfschmerzen, Reizbarkeit und Schlaflosigkeit, Angst und Misstrauen gleichzeitig behandelt werden.',
		'Die Homöopathie ist in der Welt weit verbreitet und wird als offizielle therapeutische Methode anerkannt, die in medizinischen Instituten und Universitäten studiert wird. Sie ist von Zusatzversicherungen den Krankenkassen anerkannt',
	]

	return (
		<div className="container special">
			<h1 style={{ marginBottom: '20px' }}>7 Gründe für Homöopathie</h1>

			<OrderedBulletList content={content} />

			<div style={{ marginTop: '20px' }}>
				<strong>Und das wichtigste:</strong> die Homöopathie funktioniert trotz der
				Meinungen zahlreicher Gegner!
			</div>
		</div>
	)
}
